import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useTheme } from '@mui/material';
import styled from '@emotion/styled';
import SettingsBlockContainer from '@shared/ui/settings/SettingsBlockContainer';
import Button from '@shared/ui/buttons/Button';
import PaymentSelect from '@shared/ui/misc/PaymentSelect';
import PaymentSystem from '@shared/api/payment-system';
import { openPaymentMethodForm, PaymentMethodFormType } from '@entities/current-user';
import TooltipLabel from './components/TooltipLabel';
import PlatformBalance from './components/PlatformBalance';
import EmptySelect from './components/EmptySelect';
const AddBtnContainer = styled.div(() => ({
    whiteSpace: 'nowrap',
    alignSelf: 'end',
}));
const SelectsContainer = styled.div(props => ({
    display: 'flex',
    gap: props.theme.spacing_sizes.xs * 7,
    marginBottom: props.theme.spacing_sizes.m,
}));
const SelectContainer = styled.div(props => ({
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    gap: props.theme.spacing_sizes.xm,
}));
const OPTIONS_MOCK = [
    {
        label: '*******84875',
        value: '2',
        hasError: false,
        paymentSystem: PaymentSystem.PayPal,
        assetsSum: 124,
        currency: '$',
    },
    {
        label: '*******84875',
        value: '3',
        hasError: true,
        paymentSystem: PaymentSystem.MasterCard,
        assetsSum: 241,
        currency: '$',
    },
];
const BALANCE_DETAILS = {
    currency: '$',
    assetsSum: 125.01,
};
const PaymentMethod = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const theme = useTheme();
    const handleMethodSelect = () => { };
    const handleMethodAdd = () => {
        dispatch(openPaymentMethodForm({ type: PaymentMethodFormType.AddPaymentMethod }));
    };
    const handleMethodEdit = () => {
        dispatch(openPaymentMethodForm({ type: PaymentMethodFormType.EditPaymentMethod }));
    };
    return (_jsx(SettingsBlockContainer, { title: t('settings.tabs.subscription.payment_method.title'), padding: theme.spacing_sizes.s * 5, children: _jsxs(SelectsContainer, { children: [_jsxs(SelectContainer, { children: [_jsx(PlatformBalance, { label: t('settings.tabs.subscription.payment_method.main'), tooltipText: t('settings.tabs.subscription.payment_method.main'), innerText: t('settings.tabs.subscription.payment_method.platform_balance'), amount: BALANCE_DETAILS.assetsSum, currency: BALANCE_DETAILS.currency }), _jsx(AddBtnContainer, { children: _jsx(Button, { variant: 'filled', color: 'primary', size: 'large', children: t('settings.tabs.subscription.payment_method.add_balance') }) })] }), _jsx(SelectContainer, { children: OPTIONS_MOCK.length ? (_jsx(PaymentSelect, { label: (_jsx(TooltipLabel, { labelText: t('settings.tabs.subscription.payment_method.secondary'), tooltipText: t('settings.tabs.subscription.payment_method.secondary') })), paymentErrorText: t('settings.tabs.subscription.payment_method.payment_method_error'), options: OPTIONS_MOCK, onChange: handleMethodSelect, onMethodAdd: handleMethodAdd, onMethodEdit: handleMethodEdit, value: '2' })) : (_jsx(EmptySelect, { onMethodAdd: handleMethodAdd })) })] }) }));
};
export default PaymentMethod;
