import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from '@emotion/styled';
import Tooltip from '@shared/ui/tooltips/Tooltip';
import Icon from '@shared/ui/icons/Icon';
import { ReactComponent as QuestionIconComponent, } from '@icons/wolfkit-light/question-circle-light.svg';
const LabelText = styled.div(props => ({
    fontSize: 14,
    display: 'flex',
    gap: '2px',
    alignItems: 'center',
    marginBottom: props.theme.spacing_sizes.m,
    fontWeight: 500,
    color: props.theme.palette.text.primary,
    lineHeight: '20px',
}));
const QuestionIcon = styled(QuestionIconComponent)(props => ({
    width: 16,
    height: 16,
    path: {
        fill: props.theme.palette.primary.main,
    },
}));
const TooltipLabel = ({ labelText, tooltipText }) => (_jsxs(LabelText, { role: 'heading', children: [labelText, _jsx(Tooltip, { title: tooltipText, children: _jsx(Icon, { IconComponent: QuestionIcon, keepOriginalColor: true, size: 16 }) })] }));
export default TooltipLabel;
